/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable */

import { PureComponent } from 'react';

import ClickOutside from 'Component/ClickOutside';
import Overlay from '../Overlay/Overlay.container';
import { FieldReactEvents } from 'Component/Field/Field.type';
import { ReactElement } from 'Type/Common.type';
import { Option } from 'Type/Field.type';
import { noopFn } from 'Util/Common';

import sort_icon from '../../style/icons/sort_icon.svg';
import cancel_icon from '../../style/icons/close-icon.svg';
import { FieldSelectComponentProps } from './FieldSelect.type';

import './FieldSelect.style';
import CloseIcon from 'Component/CloseIcon';

/**
 * Field Select
 * @class FieldSelect
 * @namespace TrouperPwa/Component/FieldSelect/Component */
export class FieldSelectComponent extends PureComponent<FieldSelectComponentProps> {
    static defaultProps: Partial<FieldSelectComponentProps> = {
        isUpDirection: false,
    };

    state = {
        isMobile: window.innerWidth < 850,
        isDropdownVisible: false,
    };
    

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ isMobile: window.innerWidth < 850 });
    };

    toggleDropdownVisibility = () => {
        this.setState((prevState) => ({
             // @ts-ignore
          isDropdownVisible: !prevState.isDropdownVisible,
        }));
      };

    renderNativeOption(option: Option): ReactElement {
        const {
            id,
            value,
            disabled,
            label,
            subLabel = '',
            isAvailable = true,
        } = option;

        const { isDisabled } = this.props;

        return (
            <option
              key={ id }
              id={ String(id) }
              value={ value }
              disabled={ disabled || isDisabled || !isAvailable }
            >
                { `${label} ${subLabel}` }
            </option>
        );
    }

    handleOptionSelect = (option: Option) => {
        const { handleSelectListOptionClick } = this.props;
    
        // Perform the selection logic
        handleSelectListOptionClick(option);
    
        // Close the overlay
        this.toggleDropdownVisibility();
    };

    
    renderNativeSelect(): ReactElement {
        const {
            setRef,
            attr,
            events,
            isDisabled,
            options,
            handleSelectListOptionClick,
            isSelectedOptionAvailable,
        } = this.props;

        return (
            <select
              block="FieldSelect"
              elem="Select"
              mods={ { isDisabled: !isSelectedOptionAvailable } }
              ref={ (elem) => setRef(elem) }
              disabled={ isDisabled }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...attr }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...events as FieldReactEvents<HTMLSelectElement> }
              onChange={ handleSelectListOptionClick }
            >
                { options.map(this.renderNativeOption.bind(this)) }
            </select>
        );
    }

    renderOption(option: Option): ReactElement {
        const {
            id,
            label,
            subLabel,
            isPlaceholder = false,
            isHovered,
            isAvailable = true,
        } = option;

        const {
            isExpanded,
            handleSelectListOptionClick,
        } = this.props;

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ {
                  isDisabled: !isAvailable,
                  isExpanded,
                  isPlaceholder,
                  isHovered,
              } }
              key={ id }
              id={ `o${id}` }
              role="menuitem"
              // eslint-disable-next-line react/jsx-no-bind
              onMouseDown={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onTouchStart={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? 0 : -1 }
            > <input type="radio" />
                { label }
                { subLabel && (
                    <strong>
                        { ` ${subLabel}` }
                    </strong>
                ) }
            </li>
        );
    }

    renderOptions(): ReactElement {
        const {
            options,
            isExpanded,
            isDropdownOpenUpwards,
            isScrollable,
        } = this.props;


        const { isMobile, isDropdownVisible } = this.state;

    if (isMobile) {
        return (
            <Overlay
              id="mobileOptionsOverlay"
              isOpen={ isDropdownVisible }
              mods={{ isOpen: isDropdownVisible }}
            >
                <div block="FieldSelect" elem="SortHeading" style={{display:"flex",justifyContent:"space-between",backgroundColor:"blue"}}>
                <p>Sort by</p>
                <div
                  onClick={ this.toggleDropdownVisibility }
                //   id="closeOptionsOverlay"
                //   block="FieldSelect"
                //   elem="CloseButton"
                >
                    <img src={cancel_icon} alt="cancel_icon" />
                </div>
                </div>
                <div block="FieldSelect" elem="MobileOptionsList">
                { options.map(this.renderOption.bind(this)) }
                </div>
            </Overlay>
        );
    }

    if (!isMobile) {

        return (
            <ul
              block="FieldSelect"
              elem="Options"
              role="menu"
              mods={ {
                  isExpanded,
                  isDropdownOpenUpwards,
                  isNotScrollable: !isScrollable,
              } }
            >
                <div
                  block="FieldSelect"
                  elem="OptionsWrapper"
                  role="menu"
                  mods={ { isExpanded } }
                >
                    { options.map(this.renderOption.bind(this)) }
                </div>
            </ul>
        );
    }
    }

    renderSortSelect(): ReactElement {
        const { isSortSelect } = this.props;

        if (!isSortSelect) {
            return null;
        }

        return (
            <div block="FieldSelect" elem="SortSelect">{ __('Recommended') }</div>
        );
    }
    

    render(): ReactElement {
        const {
            attr: { id = '' } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            isDisabled,
        } = this.props;

        const { isMobile } = this.state;

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  id={ `${ id }_wrapper` }
                  block="FieldSelect"
                  mods={ { isExpanded } }
                  onClick={ !isDisabled ? handleSelectExpand : noopFn }
                  onKeyPress={ !isDisabled ? handleSelectListKeyPress : noopFn }
                  role="button"
                  tabIndex={ 0 }
                  aria-label="Select dropdown"
                  aria-expanded={ isExpanded }
                >
                    <div block="FieldSelect" elem="Clickable" mods={ { isExpanded } }>
                        { this.renderSortSelect() }
                        { this.renderNativeSelect() }
                    </div>
                     { this.renderOptions() } 
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelectComponent;
